import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import { useQuery } from "react-query";
import type { ProductCatalogType } from "../../../../../contracts";
import Available from "../../../Available/Available";
import { AddToCartControl, AfterAddedToCartKitPropsType, Badges, ButtonToggleFavorite, Counter, cssMediaModal, GallerySliderPropsType, ImageContainer, PreviewSlider, Price, StoresAvailablePropsType, Title, UnitControl } from "../../parts";
import { CodeArticleInfo } from "../../parts/CodeArticleInfo";
import { ProductWrapper } from "../../ProductWrapper";
import { cssProductPreview, cssZoomIn, ProductContainer, ProductDetailInfo, StyledFloatControls } from "../../StyledProduct";
import type { ViewProductsType } from "../../types";
import { fetchProductsList } from "@/api/productsAPI";
import { ButtonToggleCompares } from "@/components/Products/parts/ButtonToggleCompares";
import { useProduct } from "@/hooks/product/product";
import { useWindowSize } from "@/hooks/windowSize";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { EntityImage, ImagePropsType } from "@/ui/EntityImage/EntityImage";
import { Modal } from "@/ui/Modal/Modal";
import { VIEW_PRODUCTS_GRID } from "@/utils/constants";
import { sortProductsWeightRule } from "@/utils/helpers";
import { IMAGE_CONFIG } from "components/Products/constants";
const ListInfo = dynamic(((() => import("../../parts").then(mod => mod.StoresAvailable)) as DynamicOptions<StoresAvailablePropsType>));
const AfterAddedToCartKit = dynamic(((() => import("../../parts").then(mod => mod.AfterAddedToCartKit)) as DynamicOptions<AfterAddedToCartKitPropsType>), {
  ssr: false
});
const GallerySlider = dynamic(((() => import("../../parts").then(mod => mod.Gallery)) as DynamicOptions<GallerySliderPropsType>), {
  ssr: false
});
type CatalogProductPropsType = {
  product: ProductCatalogType;
  view: ViewProductsType;
  index?: number;
  isShared?: boolean;
  isPreview?: boolean;
  sortWeight?: number;
  categories?: string;
  disabledHover?: boolean;
  image?: Pick<ImagePropsType, "width" | "height">;
};
const Product: FC<CatalogProductPropsType> = memo(({
  product,
  view,
  isShared = false,
  isPreview = false,
  sortWeight,
  categories,
  disabledHover = false,
  image: imageConfig,
  ...props
}) => {
  const productHook = useProduct({
    product: product,
    options: {
      isSaveOnRemove: false,
      initQty: product.initQty
    }
  });
  const {
    isFavorites,
    availableStatus,
    toggleFavorite,
    units,
    updateCurrentCount,
    updateCurrentUnit,
    totalQty,
    priceCalculate,
    currentUnit,
    isFetching,
    addToCart,
    inCart,
    isRemoved,
    storesQty,
    storesAvailable,
    counter,
    currentCount,
    isCountError: isCountProductError,
    setIsCountError,
    isInit: isInitProduct,
    isAdded,
    kitParents,
    setIsAdded,
    uuid,
    priceUnit,
    kit,
    unitMeasure,
    isKit,
    isBestseller,
    alias,
    isNew,
    name,
    images,
    path,
    isAvailable,
    analogs: analogsConcated,
    analogsIds,
    code,
    article,
    isCompares,
    toggleCompares
  } = productHook;
  const [isHover, setIsHover] = useState<boolean>(false);
  const productRef = useRef<HTMLDivElement>(null);
  const isInitEntity = useMemo(() => {
    return isInitProduct && uuid !== null;
  }, [isInitProduct, uuid]);
  const companionsIds = (product?.companions || []).map(item => item.uuid || "");
  const {
    data: kitProducts
  } = useQuery(["productAnalogs", isAdded, kit], () => fetchProductsList({
    uuids: kit.join(",")
  }), {
    enabled: isAdded && kit.length > 0
  });
  const {
    data: analogsData
  } = useQuery(["productAnalogs", isAdded, analogsIds], () => fetchProductsList({
    uuids: analogsIds.join(",")
  }), {
    enabled: isAdded && analogsIds.length > 0
  });
  const {
    data: companionsData
  } = useQuery(["companions", isAdded, companionsIds], () => fetchProductsList({
    uuids: companionsIds.join(",")
  }), {
    enabled: isAdded && companionsIds.length > 0
  });
  const analogs = useMemo(() => !!analogsData ? sortProductsWeightRule({
    products: analogsData,
    rules: analogsConcated
  }) : [], [analogsData, analogsConcated]);
  const companions = useMemo(() => !!companionsData ? sortProductsWeightRule({
    products: companionsData,
    rules: product?.companions
  }) : [], [companionsData, product?.companions]);
  const {
    width
  } = useWindowSize();
  const isLessMd = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const onMouseEventHandle = useCallback((value: boolean) => {
    if (!isInitEntity || view !== VIEW_PRODUCTS_GRID || disabledHover || isLessMd) {
      setIsHover(false);
      return;
    }
    setIsHover(value);
  }, [disabledHover, isInitEntity, isLessMd, view]);
  const onMouseEnterHandle = () => {
    onMouseEventHandle(true);
  };
  const onMouseLeaveHandle = () => {
    onMouseEventHandle(false);
  };
  const isSliderImages = view === VIEW_PRODUCTS_GRID && images.length > 1;
  const withBadges = isBestseller || isKit || isNew;
  useEffect(() => {
    if (isLessMd) {
      onMouseLeaveHandle();
    }
  }, [isLessMd]);
  return <ProductWrapper {...props} uuid={uuid || undefined} data-alias={alias} sortWeight={sortWeight} categories={categories} isHover={isHover} isFavorite={isFavorites} viewProductsVariant={view} availableStatus={availableStatus || undefined} withBadges={withBadges} onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} className={cx(isPreview && cssProductPreview)} isLoading={!isInitEntity} ref={productRef} style={{
    minHeight: isHover ? productRef?.current?.offsetHeight : undefined
  }}>
        <ProductContainer>
          <Badges isBestseller={isBestseller} isKit={isKit} isNew={isNew} />

          <StyledFloatControls>
            <ButtonToggleFavorite isFavorites={isFavorites} toggleFavorite={toggleFavorite} />
            <ButtonToggleCompares isCompares={isCompares} toggleCompares={toggleCompares} />
          </StyledFloatControls>

          <ImageContainer path={path} isSlider={isSliderImages}>
            {isSliderImages ? <PreviewSlider alt={name || ""} images={images} url={path} layout={"intrinsic"} width={imageConfig?.width || IMAGE_CONFIG.grid.size.width} height={imageConfig?.height || IMAGE_CONFIG.grid.size.height} objectFit={"contain"} quality={IMAGE_CONFIG.grid.quality} /> : <EntityImage imagePath={images.length > 0 ? images[0] : undefined} imageAlt={name || ""} layout={"intrinsic"} width={imageConfig?.width || IMAGE_CONFIG.grid.size.width} height={imageConfig?.height || IMAGE_CONFIG.grid.size.height} objectFit={"contain"} quality={IMAGE_CONFIG.grid.quality} />}

            {images.length > 0 && <Modal closeMode={"destroy"} variant={"full"} disclosure={<Button icon={"ZoomIn"} variant={"box"} className={cx(cssZoomIn)} aria-label={"Увеличить изображение"} seoText={"Увеличить изображение"} />} classNameBackdrop={cssMediaModal}>
                <GallerySlider images={images} />
              </Modal>}
          </ImageContainer>

          <Title name={name || ""} article={article} code={code} path={path} />

          <Price price={priceUnit} unitMeasure={unitMeasure} />

          {isAvailable && <>
              {!isPreview && !isShared && <UnitControl setCurrentUnit={updateCurrentUnit} unitMeasure={unitMeasure} units={units} totalQty={totalQty} unitValueActive={currentUnit || undefined} isInitProduct={isInitEntity} />}
              <Counter counter={counter} currentCount={currentCount} currentUnit={currentUnit} initialUnit={+units[0]?.value ?? null} unitMeasure={unitMeasure} maxCount={totalQty} isFetching={isFetching} updateCountHandler={updateCurrentCount} productInCart={inCart} productIsRemove={isRemoved} isCountError={isCountProductError} setIsCountError={setIsCountError} isInitProduct={isInitEntity} isStatic={isShared && !inCart || isPreview} />
            </>}

          {!isPreview && <AddToCartControl uuid={uuid || null} isAvailable={isAvailable} isFetching={isFetching} inCart={inCart && !isRemoved} addToCart={addToCart} buttonAreaContent={<>
                  <Price variant={"total"} price={priceCalculate} />
                </>} />}

          {isPreview && <Price variant={"total"} price={isAvailable ? priceCalculate : 0} messageImportant={isRemoved && isAvailable ? undefined : "нет в наличии"} />}

          <ListInfo storesQty={storesQty} stores={storesAvailable} />

          <Available status={availableStatus} />

          {isAvailable && <>
              {!isLessMd && <ProductDetailInfo>
                  <CodeArticleInfo code={code} article={article} />
                </ProductDetailInfo>}
            </>}

          {isKit && <Modal closeMode={"destroy"} variant={"rounded-70"} isShowModal={isAdded} title={"Добавлен в корзину"} hideOnClickOutside={false}>
              <AfterAddedToCartKit product={product} companions={companions} kitProducts={kitProducts || []} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
            </Modal>}

          {kitParents.length > 0 && <Modal closeMode={"destroy"} variant={"rounded-70"} isShowModal={isAdded} title={"Добавлен в корзину"} hideOnClickOutside={false}>
              <AfterAddedToCartKit product={product} companions={companions} kitParents={kitParents || []} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
            </Modal>}
        </ProductContainer>
      </ProductWrapper>;
});
Product.displayName = "Product";
export { Product };
export type { CatalogProductPropsType };