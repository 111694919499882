import { FC } from "react"
import { useQuery } from "react-query"
import { ProductCatalogType } from "../../../../contracts"
import { ProductsGrid } from "../../Products/Grid"
import { Header } from "./Header/Header"
import { StyledEmpty } from "./Styled"
import { fetchHits } from "@/api/catalogAPI"
import { StyledTileProducts } from "@/components/Products"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import {
  resetHitsCurrentCategory,
  setHits,
  setHitsCurrentCategory,
} from "@/store/reducers/appSlice"
import { Container } from "@/styles/utils/StyledGrid"
import { SectionProducts } from "@/styles/utils/Utils"
import { VIEW_PRODUCTS_GRID } from "@/utils/constants"

export type HitsPropsType = { initial?: ProductCatalogType[] }

const Hits: FC<HitsPropsType> = () => {
  const dispatch = useAppDispatch()
  const currentCategoryUUID = useAppSelector(
    (state) => state.app.hits.currentCategory,
  )
  const products = useAppSelector((state) =>
    !!currentCategoryUUID
      ? state.app.hits.byCategory[currentCategoryUUID]
      : state.app.hits.initial,
  )

  useQuery(
    ["products", "hits", currentCategoryUUID],
    () =>
      fetchHits({
        category: `${currentCategoryUUID}`,
      }),
    {
      onSuccess(products) {
        if (!currentCategoryUUID) {
          return
        }
        dispatch(
          setHits({
            categoryId: currentCategoryUUID,
            products,
          }),
        )
      },
      enabled: !!currentCategoryUUID,
    },
  )

  return (
    <SectionProducts>
      <Container>
        <Header
          onSelectCategory={(categoryId) =>
            dispatch(setHitsCurrentCategory(categoryId))
          }
          onUnselectCategory={() => dispatch(resetHitsCurrentCategory())}
        />
        {!!products && products.length > 0 ? (
          <ProductsGrid
            as={StyledTileProducts}
            products={products}
            view={VIEW_PRODUCTS_GRID}
          />
        ) : !!currentCategoryUUID ? (
          <StyledEmpty>В выбранной категории нет товаров-хитов</StyledEmpty>
        ) : null}
      </Container>
    </SectionProducts>
  )
}

Hits.displayName = "Hits"

export { Hits }
