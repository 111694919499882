import type { BaseHTMLAttributes, FC } from "react";
import { cx } from "@linaria/core";
import { cssIsSlider, StyledImageContainer } from "./StyledImageContainer";
import { Link } from "@/ui/Link";
export type ProductImageContainerPropsType = {
  path: string | null;
  isSlider?: boolean;
};
export const ImageContainer: FC<ProductImageContainerPropsType & BaseHTMLAttributes<HTMLDivElement>> = ({
  children,
  path,
  isSlider,
  className,
  ...props
}) => {
  return <StyledImageContainer {...props} className={cx(!!isSlider && cssIsSlider, className)}>
      {!isSlider && <Link href={path || ""} scroll />}
      {children}
    </StyledImageContainer>;
};